import { SportMenuOption } from "rivals/components/shared/Menu/SportMenu/types";

export const sportMenuOptionFromString = (value?: string): SportMenuOption => {
  switch (value?.toLowerCase()) {
    case "all":
      return SportMenuOption.All;
    case "football":
      return SportMenuOption.Football;
    case "basketball":
      return SportMenuOption.Basketball;
    case "baseball":
      return SportMenuOption.Baseball;
    case "other":
      return SportMenuOption.Other;
    default:
      return SportMenuOption.All;
  }
};
