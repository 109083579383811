import { JSX, useState } from "react";
import Link from "rivals/components/shared/Link";
import Menu from "rivals/components/shared/Menu";
import { PATHS } from "rivals/shared/constants";
import styles from "./SportMenu.module.scss";
import { Props, SportMenuOption } from "./types";

function SportMenu({
  onChange,
  sports = [],
  value,
  ...props
}: Props): JSX.Element | null {
  const [loadingSport, setLoadingSport] = useState<string>();

  if (sports.length <= 1) {
    return null;
  }

  const items = [SportMenuOption.All, ...sports].map((sport: string) => {
    let path: string = PATHS.HOME;
    if (sport !== SportMenuOption.All) {
      path = PATHS.HOME_WITH_SPORT.replace("[sport]", sport.toLowerCase());
    }

    return {
      key: sport.toLowerCase(),
      label: (
        <Link
          forceNextLink
          href={path}
          key={sport.toLowerCase()}
          onClick={() => {
            const sportVal = sport.toLowerCase();
            setLoadingSport(sportVal === value ? undefined : sportVal);
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <span className={styles.label}>{sport}</span>
          </div>
        </Link>
      )
    };
  });

  return (
    <Menu
      className={loadingSport && styles.menuWithHighlight}
      disabledOverflow
      items={items}
      mode="horizontal"
      onClick={
        onChange
          ? ({ key }): void => {
              onChange(key as SportMenuOption);
            }
          : undefined
      }
      selectedKeys={[loadingSport || value]}
      {...props}
    />
  );
}

export default SportMenu;
