"use client";

import { useMemo } from "react";
import styles from "rivals/components/pages/Homepage/Dashboard/Dashboard.module.scss";
import TeamSelect from "rivals/components/shared/TeamSelector";
import {
  getCollegesFromTeamSelectionItems,
  getHighSchoolsFromTeamSelectionItems,
  getTeamSelectionItemsFromSites
} from "rivals/components/shared/TeamSelector/utils";
import localStyles from "./TeamSelector.module.scss";
import type { Props } from "./types";

export default function TeamSelector({ sites }: Props): JSX.Element {
  const teamSelectionItems = getTeamSelectionItemsFromSites(sites);
  const colleges = useMemo(
    () => getCollegesFromTeamSelectionItems(teamSelectionItems),
    [teamSelectionItems]
  );
  const highSchools = useMemo(
    () => getHighSchoolsFromTeamSelectionItems(teamSelectionItems),
    [teamSelectionItems]
  );

  return (
    <div className={localStyles.container}>
      <TeamSelect
        colleges={colleges}
        highSchools={highSchools}
        wrapperClassName={styles.dashboardTeamSelector}
      />
    </div>
  );
}
