import { Providers } from "rivals/components/shared/VideoPlayer/types";
import { Site } from "rivals/shared/interfaces/Site";

interface PreviousCommitData {
  collegeLogo: string;
  collegeName: string;
  endingDate: string;
  site: Site;
  startingDate: string;
}

export interface Prospect {
  announcementDate: string | null;
  athleteSlug: string;
  athleteUrl: string;
  bigCommitLogo: string | null;
  canForecast: boolean;
  city: string;
  collegePlayerId: number | null;
  commitDate: string | null;
  committedCollegeId: number | null;
  committedCollegeLogo: string | null;
  committedCollegeName: string | null;
  committedCollegeNickname: string | null;
  committedSiteName: string | null;
  firstName: string;
  fullName: string;
  goingPro?: boolean;
  height: number | null;
  highschoolName: string | null;
  hometown: string;
  hometownMapUrl?: string;
  images: string[];
  lastName: string;
  latestRankDate: string;
  loiTransfer: boolean;
  nationalRank: number | null;
  nationalRankChange: number;
  nationalRankUrl: string | null;
  personId: string;
  positionAbbreviation: string;
  positionGroupAbbreviation: string;
  positionGroupName: string;
  positionName: string;
  positionRank: number | null;
  positionRankChange: number;
  positionRankUrl: string | null;
  previousCommitData: PreviousCommitData | null;
  profileImageUrl: string | null;
  prospectId: number;
  prospectProfileAlt: string | null;
  recruitYear: number;
  rivalsRating: number | null;
  schoolCommitsUrl: string | null;
  schoolName: string | null;
  slug: string;
  sport: string;
  spotlightVideoProvider?: keyof Providers;
  spotlightVideoRemote?: string;
  stars: number;
  state: string;
  stateRank: number | null;
  stateRankChange: number;
  stateRankUrl: string | null;
  status: string;
  twitterCardDescription: string | null;
  twitterHandle: string | null;
  twitterImage: string | null;
  url: string;
  verifiedHeight: number | null;
  verifiedWeight: number | null;
  videos: { remoteId: string; source: keyof Providers }[];
  weight: number | null;
}

export interface ProspectCollegeInterest {
  collegeId: number;
  commit: boolean;
  commitDate: string | null;
  commitmentsUrl: string;
  id: number;
  interest: string;
  offer: boolean;
  recruiters: string[];
  sign: boolean;
  siteId: number;
  siteName: string;
  teamLogo: string;
  teamName: string;
  visits: SchoolVisit[];
}

export interface SchoolVisit {
  id: number;
  visitDate: string;
  visitOfficial: boolean;
}

export interface InterestSpread {
  anyInterests: boolean;
  highInterests: number;
  lowInterests: number;
  mediumInterests: number;
  totalInterests: number;
  totalOffers: number;
}

export interface ProspectInterests {
  interestSpread?: InterestSpread;
  interests: ProspectCollegeInterest[];
}

export interface TopForecastInterest {
  logo?: string;
  name: string;
  percentage: string;
  primaryColor: string;
}

export interface ProspectTopForecasts {
  fanfuturecast: TopForecastInterest[];
  futurecast: TopForecastInterest[];
  myFuturecast: { logo: string; unlikely: boolean } | null;
}

export interface ForecastTableCollege {
  analysts: number;
  college: { logo: string; name: string; url: string };
  fans: number;
  percentage: string;
  publishers: number;
  total: number;
}

export interface ProspectForecastTable {
  data: ForecastTableCollege[];
  date: string;
}

interface RankingGraphPoint {
  areaTotal: number[];
  change: number | null;
  date: number;
  ranking: number;
  showPoint: boolean;
  tag: string;
}

export interface RankingGraphData {
  dataPoints: RankingGraphPoint[];
  domainMax: number;
  domainMin: number;
  range: number;
}

export interface ForecastGraphDatePoint {
  [key: string]: number;
  date: number;
}

export type UpdatedForecastGraphDateData = { point: number; range: number[] };
export interface UpdatedForecastGraphDatePoint {
  [key: string]: UpdatedForecastGraphDateData | number;
  date: number;
}

export interface ForecastGraphCollegeData {
  fill: string;
  key: string;
  logoUrl: string;
  tag: string;
}

export enum CareerEventType {
  DECOMMIT = "DECOMMIT",
  LOI_COMMIT = "LOI_COMMIT",
  OFFER = "OFFER",
  PORTAL_ENTRY = "PORTAL_ENTRY",
  PORTAL_INTENT = "PORTAL_INTENT",
  PORTAL_NOT_SIGNED = "PORTAL_NOT_SIGNED",
  PORTAL_TRANSFER = "PORTAL_TRANSFER",
  PORTAL_WITHDRAW = "PORTAL_WITHDRAW",
  SOFT_COMMIT = "SOFT_COMMIT",
  VERBAL_COMMIT = "VERBAL_COMMIT",
  VISIT = "VISIT",
  UNOFFICIAL_VISIT = "UNOFFICIAL_VISIT"
}

export enum ProspectStatus {
  SIGNED = "signed",
  SOFT = "soft",
  VERBAL = "verbal",
  UNDECIDED = "undecided"
}

export interface CareerEvent {
  collegeColor?: string;
  collegeId: number;
  collegeLogo?: string;
  collegeName: string;
  eventDate: Date | string;
  eventId: number;
  eventType: CareerEventType;
  formattedDate: string;
  personId: number;
}

export interface CollegeHash {
  [key: string]: {
    fill: string;
    logoUrl: string;
    tag: string;
  };
}

export interface ProspectForecastGraphData {
  collegeData: CollegeHash;
  forecastData: UpdatedForecastGraphDatePoint[];
  orderedKeys: number[];
}

export interface ListForecast {
  analyst: boolean;
  analystProfileUrl?: string | null;
  collegeLogo: string;
  forecastDate: string;
  forecastId: number;
  previousCollegeLogo?: string | null;
  previousUnlikelyForecast: boolean;
  reason?: string | null;
  unlikelyForecast: boolean;
  username: string;
}

export interface GraphForecastList {
  [key: string]: ListForecast[];
}

export interface ProspectRankingsData {
  athleteUrl: string;
  collegeColor: string | null;
  collegeLogo: string | null;
  collegeName: string | null;
  commitDate: string | null;
  commitmentsUrl?: string | null;
  firstName: string;
  height: number | null;
  highschool: string | null;
  hometown: string | null;
  lastName: string;
  ordinality: number;
  positionAbbreviation: string | null;
  profileImageUrl: string;
  rankChange: number;
  siteName: string | null;
  stars: number;
  status: ProspectStatus;
  weight: number | null;
}

export interface CommitmentData {
  athleteUrl: string;
  commitDate?: string;
  firstName: string;
  height?: number;
  hometown?: string;
  id: number;
  lastName: string;
  nationalRank: string | number;
  nationalRankChange: number;
  positionAbbreviation: string;
  profileImageUrl?: string;
  rivalsRating?: number;
  stars: number;
  status?: ProspectStatus;
  weight?: number;
}
