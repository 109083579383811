import classNames from "classnames";
import CustomButton from "rivals/components/shared/CustomButton";
import Chevron from "rivals/components/shared/Icons/Chevron";
import styles from "./ControlArrow.module.scss";
import strings from "./strings";
import type { Props } from "./types";

const ControlArrow = ({ philter, ...props }: Props): React.JSX.Element => {
  const { onClick } = props;
  const isNextArrow = props.type === "next";
  const classes = classNames(styles.controlArrow, {
    [styles.prevArrow]: !isNextArrow,
    [styles.nextArrow]: isNextArrow
  });
  const icon = (
    <Chevron
      fill={styles["white"]}
      height="16px"
      title={isNextArrow ? strings.next : strings.prev}
      width="16px"
    />
  );

  return (
    <CustomButton
      ariaLabel={isNextArrow ? strings.next : strings.prev}
      className={classes}
      icon={icon}
      onClick={onClick}
      philter={`${philter}-${isNextArrow ? "next" : "previous"}-slide`}
    />
  );
};

export default ControlArrow;
