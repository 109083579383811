import { CareerEventType } from "rivals/shared/interfaces/Prospect";
import styles from "rivals/styles/exports.module.scss";
import { CareerEventDisplayData } from "./types";

export const eventDisplayData: {
  [key in CareerEventType]: CareerEventDisplayData;
} = {
  [CareerEventType.DECOMMIT]: {
    icon: "/static/icons/career_highlights/decommit.svg",
    name: "Decommitment"
  },
  [CareerEventType.LOI_COMMIT]: {
    icon: "/static/icons/career_highlights/signed_loi.svg",
    name: "Signed"
  },
  [CareerEventType.OFFER]: {
    icon: "/static/icons/career_highlights/offer.svg",
    name: "School offer"
  },
  [CareerEventType.PORTAL_ENTRY]: {
    icon: "/static/icons/career_highlights/entered_transfer_portal.svg",
    name: "Entered transfer portal"
  },
  [CareerEventType.PORTAL_INTENT]: {
    icon: "/static/icons/career_highlights/portal_intent.svg",
    name: "Intends to enter transfer portal"
  },
  [CareerEventType.PORTAL_TRANSFER]: {
    icon: "/static/icons/career_highlights/transferred_to.svg",
    name: "Transferred to"
  },
  [CareerEventType.PORTAL_WITHDRAW]: {
    icon: "/static/icons/career_highlights/withdraw.svg",
    name: "Withdrew from transfer portal"
  },
  [CareerEventType.PORTAL_NOT_SIGNED]: {
    icon: "/static/icons/career_highlights/not_signed.svg",
    name: "Not signed in transfer portal"
  },
  [CareerEventType.SOFT_COMMIT]: {
    icon: "/static/icons/career_highlights/soft_commit.svg",
    name: "Soft verbal commit"
  },
  [CareerEventType.VERBAL_COMMIT]: {
    icon: "/static/icons/career_highlights/verbal_commit.svg",
    name: "Verbal commit"
  },
  [CareerEventType.VISIT]: {
    icon: "/static/icons/career_highlights/school_official_visit.svg",
    name: "Official school visit"
  },
  [CareerEventType.UNOFFICIAL_VISIT]: {
    icon: "/static/icons/career_highlights/school_unofficial_visit.svg",
    name: "Unofficial school visit"
  }
};

export const eventTypes = {
  [CareerEventType.LOI_COMMIT]: "Signed",
  [CareerEventType.PORTAL_TRANSFER]: "Transferred To",
  [CareerEventType.DECOMMIT]: "Decommit",
  [CareerEventType.OFFER]: "Offer",
  [CareerEventType.VISIT]: "Visit",
  [CareerEventType.PORTAL_ENTRY]: "Entered Portal",
  [CareerEventType.PORTAL_INTENT]: "Intends to Enter",
  [CareerEventType.PORTAL_NOT_SIGNED]: "Stayed With",
  [CareerEventType.VERBAL_COMMIT]: "Verbal Commit",
  [CareerEventType.PORTAL_WITHDRAW]: "Withdrew",
  [CareerEventType.SOFT_COMMIT]: "Soft Commit",
  [CareerEventType.UNOFFICIAL_VISIT]: "Visit"
};

export const bannerColors = {
  [CareerEventType.LOI_COMMIT]: styles.pass,
  [CareerEventType.PORTAL_TRANSFER]: styles.pass,
  [CareerEventType.DECOMMIT]: styles["rivals-red"],
  [CareerEventType.OFFER]: styles.offerRed,
  [CareerEventType.VISIT]: styles["rivals-primary"],
  [CareerEventType.PORTAL_ENTRY]: styles.portalEntryYellow,
  [CareerEventType.PORTAL_INTENT]: styles.darkSkyBlue,
  [CareerEventType.PORTAL_NOT_SIGNED]: styles.gray60,
  [CareerEventType.VERBAL_COMMIT]: styles.corn,
  [CareerEventType.PORTAL_WITHDRAW]: styles["rivals-red"],
  [CareerEventType.SOFT_COMMIT]: styles.gray60,
  [CareerEventType.UNOFFICIAL_VISIT]: styles["rivals-primary"]
};

export const textColors = {
  [CareerEventType.LOI_COMMIT]: styles.bone,
  [CareerEventType.PORTAL_TRANSFER]: styles.bone,
  [CareerEventType.DECOMMIT]: styles.bone,
  [CareerEventType.OFFER]: styles.bone,
  [CareerEventType.VISIT]: styles.bone,
  [CareerEventType.PORTAL_ENTRY]: styles.abyss,
  [CareerEventType.PORTAL_INTENT]: styles.bone,
  [CareerEventType.PORTAL_NOT_SIGNED]: styles.bone,
  [CareerEventType.VERBAL_COMMIT]: styles.abyss,
  [CareerEventType.PORTAL_WITHDRAW]: styles.bone,
  [CareerEventType.SOFT_COMMIT]: styles.bone,
  [CareerEventType.UNOFFICIAL_VISIT]: styles.bone
};
