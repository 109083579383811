import { DoubleRightOutlined } from "@ant-design/icons";
import classNames from "classnames";
import React from "react";
import {
  bannerColors,
  eventDisplayData,
  eventTypes,
  textColors
} from "rivals/components/pages/CareerHighlights/Card/utils";
import Avatar from "rivals/components/shared/Avatar";
import CollegeLogo from "rivals/components/shared/CollegeLogo";
import { isMulticollegeCard } from "rivals/components/shared/EventTicker/utils";
import Unknown from "rivals/components/shared/Icons/Unknown";
import Link from "rivals/components/shared/Link";
import Stars from "rivals/components/shared/Stars";
import { CareerType } from "rivals/shared/interfaces/Career";
import { CareerEventType } from "rivals/shared/interfaces/Prospect";
import { setData } from "rivals/shared/utils/qa";
import styles from "./EventCard.module.scss";
import type { Props } from "./types";

function EventCard({ event }: Props): React.JSX.Element {
  const philter = `event-card-${event.eventId}`;
  const maxFirstName = 15;
  const maxLastName = 13;
  const recruit = event.careerType == CareerType.PROSPECT;

  const singleCollegeEvent = (
    <div className={styles.content}>
      <CollegeLogo
        collegeLogo={event.collegeLogo}
        collegeName={event.collegeName}
        collegeSiteColor={event.collegeColor}
        logoSize={30}
      />
      <div className={styles.eventLabel}>{event.collegeName}</div>
    </div>
  );

  const multiCollegeEvent = (
    <div className={classNames(styles.content, styles.multiCollege)}>
      <CollegeLogo
        collegeLogo={event.collegeLogo}
        collegeName={event.collegeName}
        collegeSiteColor={event.collegeColor}
        logoSize={30}
      />
      <DoubleRightOutlined
        className={classNames(styles.transferChevron, {
          [styles.withdrawnChevron]:
            event.eventType == CareerEventType.PORTAL_WITHDRAW
        })}
        rev={""}
      />
      <Unknown height={"30px"} width={"30px"} />
    </div>
  );

  const athleteBio = (
    <div className={styles.athlete} {...setData(philter, "athlete-details")}>
      <div
        className={styles.athleteProfilePhoto}
        style={{ backgroundColor: bannerColors[event.eventType] }}
      >
        <Avatar
          alt={`${event.firstName} ${event.lastName} profile picture`}
          size={40}
          url={event.profileImageUrl}
          {...setData(philter, "athlete-profile-picture")}
        />
        <img
          alt={`${eventTypes[event.eventType]} Event Icon`}
          className={styles.eventIcon}
          src={eventDisplayData[event.eventType]?.icon}
        />
      </div>
      <div className={styles.athleteBioData}>
        <div
          className={classNames({
            [styles.longName]: event.lastName.length > maxFirstName
          })}
        >
          {event.firstName}
        </div>
        <div
          className={classNames(styles.lastName, {
            [styles.longName]: event.lastName.length > maxLastName
          })}
        >
          {event.lastName}
        </div>
        <div className={styles.position}>
          <div className={styles.positionAbbreviation}>
            {event.positionAbbreviation}
          </div>
          <Stars
            {...setData(philter, "athlete-stars")}
            className={styles.starsContainer}
            numStars={event.stars}
            size="small"
            variant={recruit ? "yellow" : "blue"}
          />
        </div>
      </div>
    </div>
  );

  const eventData = (
    <div className={styles.event} {...setData(philter, "event")}>
      <div
        {...setData(philter, "event-label")}
        className={styles.header}
        style={{
          backgroundColor: bannerColors[event.eventType],
          color: `${textColors[event.eventType]}`
        }}
      >
        {eventTypes[event.eventType]}
      </div>
      {isMulticollegeCard(event.eventType)
        ? multiCollegeEvent
        : singleCollegeEvent}
    </div>
  );

  return (
    <Link
      className={styles.eventCard}
      data={`${philter}-athlete-profile`}
      href={event.athleteUrl}
    >
      <div
        className={styles.dateHeader}
        style={{
          borderColor: recruit ? styles.recruit : styles.transfer
        }}
        {...setData(philter, "date-header")}
      >
        {event.formattedDate}
      </div>
      <div className={styles.eventContent}>
        {athleteBio}
        {eventData}
      </div>
    </Link>
  );
}

export default EventCard;
