"use client";

import Select, { SelectProps } from "antd/lib/select";
import React, { useState } from "react";
import DraggableMarquee from "rivals/components/shared/DraggableMarquee";
import Chevron from "rivals/components/shared/Icons/Chevron";
import RivalsLink from "rivals/components/shared/Link";
import { HOST_URL } from "rivals/services/base";
import useGetTickerEvents from "rivals/services/getTickerEvents/useGetTickerEvents";
import {
  PATHS,
  RIVALS_LIST_SPORTS,
  ROTATE_90,
  SEE_MORE,
  TRANSFER_PORTAL_SITE_IDENTIFIER
} from "rivals/shared/constants";
import { isNationalUmbrellaSite } from "rivals/shared/utils/nationals";
import { setData } from "rivals/shared/utils/qa";
import EventCard from "./EventCard";
import styles from "./EventTicker.module.scss";
import strings from "./strings";
import { EventSelectLabels, Props, TickerEventTypes } from "./types";

function EventTicker({
  shortName,
  siteId,
  sport,
  tickerEvents: tickerEventsProps
}: Props): React.JSX.Element {
  const philter = "events-ticker";
  const isNational = isNationalUmbrellaSite(shortName);
  const trackerSport = sport || RIVALS_LIST_SPORTS.FOOTBALL;

  const seeMoreTrackerLink = isNational
    ? `${HOST_URL}/transfer_tracker/${trackerSport}`
    : `${PATHS.TRANSFER_TRACKER_DEFAULT_YEAR.replace(
        "[sport]",
        trackerSport
      )}?school_focus=${shortName.toLowerCase()}`;

  const initialType =
    shortName === TRANSFER_PORTAL_SITE_IDENTIFIER
      ? TickerEventTypes.transfer
      : TickerEventTypes.all;

  const [selectedType, setSelectedType] = useState<TickerEventTypes>(
    initialType
  );

  // Skip the first client side fetch, we've already fetched on the server
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);

  // If shouldFetch is true, we will fetch the data if any of these args change
  const { data } = useGetTickerEvents(
    {
      siteId,
      sport,
      type: selectedType
    },
    { fallbackData: { events: tickerEventsProps }, shouldFetch }
  );

  const tickerEvents = data?.events || [];

  const [selectOpen, setSelectOpen] = useState<boolean>(false);
  const empty = !(tickerEvents && tickerEvents.length > 0);

  const onChangeType = (type: TickerEventTypes): void => {
    setSelectedType(type);
    setShouldFetch(true);
  };

  const options = [
    TickerEventTypes.all,
    TickerEventTypes.recruit,
    TickerEventTypes.transfer
  ].map(eventType => {
    return {
      label: (
        <div className={styles.optionItem}>
          <span className={styles.optionName}>{eventType}</span>
        </div>
      ),
      value: eventType
    };
  });

  const emptyState = (
    <div className={styles.emptyState}>{strings.emptyState}</div>
  );

  const tickerEventSlides = tickerEvents.map((event, index) => {
    return (
      <li
        className={styles.cardHolder}
        draggable={false}
        key={index}
        role="listitem"
        {...setData(philter, `event-card-${index}`)}
      >
        <EventCard event={event} />
      </li>
    );
  });
  // eslint-disable-next-line no-magic-numbers
  const SCROLL_SPEED = tickerEventSlides.length * 8;
  // eslint-disable-next-line no-magic-numbers
  const WIDTH = tickerEventSlides.length * (260 + 8);
  // 260: px width of a card, 4px padding right, 4px padding left

  const framerCarousel = (
    <DraggableMarquee height={98} scrollSpeed={SCROLL_SPEED} width={WIDTH}>
      {tickerEventSlides}
    </DraggableMarquee>
  );

  const ticker = (
    <ul {...setData(philter, "event-card-list")}>
      {empty && emptyState}
      {!empty && framerCarousel}
    </ul>
  );

  // Customize the selected label different than the dropdown label
  const labelRender: SelectProps["labelRender"] = props => {
    // When "All" is selected, show "Top Events" as the selected label
    type ObjectKey = keyof typeof EventSelectLabels;
    return (
      <div className={styles.optionItem}>
        <span className={styles.optionName}>
          {EventSelectLabels[props.value as ObjectKey]}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.eventTickerWrapper}>
      <div className={styles.tickerDropdown}>
        <Select
          className={styles.tickerSelector}
          labelRender={labelRender}
          onChange={onChangeType}
          onDropdownVisibleChange={(open: boolean): void => {
            setSelectOpen(open);
          }}
          options={options}
          popupClassName={styles.eventTypeSelectPopup}
          size={"large"}
          suffixIcon={
            <Chevron
              animateRotation={true}
              fill={styles.bone}
              rotate={selectOpen ? ROTATE_90 : -ROTATE_90}
            />
          }
          value={selectedType}
          variant={"borderless"}
          {...setData(philter, "sport-selector")}
        />
        {selectedType === TickerEventTypes.transfer && (
          <RivalsLink
            className={styles.seeMoreButtonWrapper}
            data={`${philter}-see-more-transfers-button`}
            href={seeMoreTrackerLink}
          >
            {SEE_MORE}
          </RivalsLink>
        )}
      </div>
      <div className={styles.eventTicker} {...setData(philter)}>
        {empty && emptyState}
        {!empty && ticker}
      </div>
    </div>
  );
}

export default EventTicker;
