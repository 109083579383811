"use client";

import { JSX } from "react";
import type { Props } from "./types";
import useBenjiRefresh from "./useBenjiRefresh";

/**
 * Component to wrap useBenjiRefresh hook
 * For use in app router server components
 *
 * When using in client components import
 * the hook directly
 */
export default function BenjiRefresh({
  slots,
  triggerState
}: Props): JSX.Element {
  useBenjiRefresh({ slots, triggerState });

  return <></>;
}
