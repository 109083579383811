import snakeCaseKeys from "snakecase-keys";
import { SWRResponse } from "swr";
import useQuery from "rivals/shared/hooks/useQuery/useQuery";
import type { UseQueryOptions } from "rivals/shared/hooks/useQuery/useQuery";
import { Request, Response } from "./types";

export default function useGetTickerEvents(
  queryParams: Request,
  options: UseQueryOptions
): SWRResponse<Response> {
  const finalQueryParams = new URLSearchParams(snakeCaseKeys(queryParams));
  const response = useQuery<Response>(
    `api/v2/content/event_ticker?${finalQueryParams}`,
    options
  );

  return response;
}
