import { TickerEvent } from "rivals/shared/interfaces/TickerEvent";

export interface Props {
  /**
   * Short Name the active subdomain.
   */
  shortName: string;
  /**
   * ID of the Site associated with the active subdomain.
   */
  siteId: number;
  /**
   * Optional name of selected sport
   */
  sport?: string;

  /**
   * An array of formatted ticker event objects
   */
  tickerEvents: TickerEvent[];
}

export enum TickerEventTypes {
  all = "all",
  recruit = "recruit",
  transfer = "transfer"
}

export enum EventSelectLabels {
  all = "Top Events",
  recruit = "Recruit Events",
  transfer = "Transfer Events"
}
