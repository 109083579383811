import { Props as MenuProps } from "rivals/components/shared/Menu/types";
import { RIVALS_LIST_SPORTS } from "rivals/shared/constants";

export interface Props extends Omit<MenuProps, "onChange" | "value"> {
  onChange?: (value: SportMenuOption) => void;
  sports: RIVALS_LIST_SPORTS[];
  value: SportMenuOption;
}

export const enum SportMenuOption {
  All = "all",
  Football = RIVALS_LIST_SPORTS.FOOTBALL,
  Basketball = RIVALS_LIST_SPORTS.BASKETBALL,
  Baseball = RIVALS_LIST_SPORTS.BASEBALL,
  Other = "other"
}
