import classNames from "classnames";
import { animate, motion, useMotionValue } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { MILLISECONDS_PER_SECOND } from "rivals/shared/constants";
import styles from "./DraggableMarquee.module.scss";
import { Props } from "./types";

const Draggable = ({
  children,
  height,
  scrollSpeed,
  width
}: Props): React.JSX.Element => {
  const xTranslation = useMotionValue(0);
  const ref = useRef<HTMLHeadingElement>(null);

  // prevents accidentally clicking a link by removing pointer events while dragging
  const [isDragging, setIsDragging] = useState(false);

  const SLOW_DURATION = scrollSpeed * MILLISECONDS_PER_SECOND; // represents stop scroll
  const DURATION = scrollSpeed;
  const [duration, setDuration] = useState(DURATION);
  const [mustFinish, setMustFinish] = useState(false);
  const [rerender, setRerender] = useState(false);
  const duplicateItems = [...children, children];

  useEffect(() => {
    const finalPosition = -width;
    // children passed in represent data and are duplicated to allow wrapping when end is reached.
    // Set the final position that triggers a rerender to the end of the original list (width param)
    let controls;

    // prevents animation quirks on hover
    if (mustFinish) {
      controls = animate(xTranslation, [xTranslation.get(), finalPosition], {
        duration: duration * (1 - xTranslation.get() / finalPosition),
        ease: "linear",
        onComplete: () => {
          setMustFinish(false);
          setRerender(!rerender);
        }
      });
    } else {
      controls = animate(xTranslation, [0, finalPosition], {
        duration: duration,
        ease: "linear",
        repeat: Infinity,
        repeatDelay: 0,
        repeatType: "loop"
      });
    }

    return controls.stop;
  }, [xTranslation, duration, rerender, mustFinish, width]);

  return (
    <motion.div
      className={classNames(styles.container, {
        [styles.dragging]: isDragging
      })}
      drag="x"
      onDrag={() => {
        setIsDragging(true);
      }}
      onDragEnd={() => {
        setIsDragging(false);
      }}
      onHoverEnd={() => {
        setMustFinish(true);
        setDuration(DURATION);
      }}
      onHoverStart={() => {
        setMustFinish(true);
        setDuration(SLOW_DURATION);
      }}
      onTouchEnd={() => {
        setMustFinish(true);
        setDuration(DURATION);
      }}
      onTouchStart={() => {
        setMustFinish(true);
        setDuration(SLOW_DURATION);
      }}
      ref={ref}
      style={{ x: xTranslation }}
    >
      <div className={styles.carousel} style={{ height: height }}>
        {duplicateItems}
      </div>
    </motion.div>
  );
};

export default Draggable;
