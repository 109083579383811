"use client";

import { Carousel } from "antd";
import chunk from "lodash/chunk";
import ContentCard from "rivals/components/shared/ContentCard";
import { CONTENT_CARD_VARIANTS } from "rivals/components/shared/ContentCard/types";
import MediaWrapper from "rivals/components/shared/Media";
import { MEDIA_BREAKPOINTS } from "rivals/media";
import ControlArrow from "./ControlArrow";
import styles from "./HeadlineNews.module.scss";
import type { Props } from "./types";

const DATA_PHILTER = "headline-news";

const CARDS_PER_SLIDE = {
  DESKTOP: 2,
  TOUCH: 3 // mobile and tablet
};

const HeadlineNews = ({
  contentItems,
  site
}: Props): React.JSX.Element | null => {
  if (!(contentItems && contentItems.length)) {
    return null;
  }

  // Destrucure the content into the first item (highlighted) and the rest
  const [highlightedContent, ...rest] = contentItems;

  const carousel = (
    variant: CONTENT_CARD_VARIANTS,
    cardsPerSlide: number,
    isTouchView?: boolean
  ): React.JSX.Element => {
    // slides will be an array of arrays aka 2D array where
    // the size of slides is the number of the carousel slides, and the size of each
    // element aka slide is the number of cards that go into each one
    const slides = chunk(rest, cardsPerSlide);

    return (
      <Carousel
        arrows={!isTouchView}
        draggable
        infinite={false}
        nextArrow={<ControlArrow philter={DATA_PHILTER} type="next" />}
        pauseOnDotsHover
        pauseOnFocus
        prevArrow={<ControlArrow philter={DATA_PHILTER} type="prev" />}
        swipeToSlide
        variableWidth
        waitForAnimate
      >
        {/* primary slide has 1 higlighted content card */}
        <div className={styles.primarySlide}>
          <ContentCard
            {...highlightedContent}
            className={styles.contentCard}
            darkMode
            philter={DATA_PHILTER}
            site={site}
            variant={CONTENT_CARD_VARIANTS.HIGHLIGHTED}
          />
        </div>

        {/* secondary slides have multiple content cards */}
        {slides.map((cards, slideIndex) => (
          <div className={styles.secondarySlide} key={slideIndex}>
            {cards.map((card, cardIndex) => (
              <ContentCard
                {...card}
                className={styles.contentCard}
                darkMode
                key={`${slideIndex}-${cardIndex}`}
                philter={DATA_PHILTER}
                site={site}
                summary=""
                variant={variant}
              />
            ))}
          </div>
        ))}
      </Carousel>
    );
  };

  return (
    <div className={styles.headlineNewsWrapper}>
      {/* On Desktop view, the first slide aka primary slide shows the first article */}
      {/* The following slides aka secondary slides each show two highlighted content cards */}
      <MediaWrapper greaterThanOrEqual={MEDIA_BREAKPOINTS.LG}>
        {carousel(CONTENT_CARD_VARIANTS.HIGHLIGHTED, CARDS_PER_SLIDE.DESKTOP)}
      </MediaWrapper>

      {/* On Touch views, the primary slide shows the first article */}
      {/* The secondary slides each show three common content cards */}
      <MediaWrapper lessThan={MEDIA_BREAKPOINTS.LG}>
        {carousel(CONTENT_CARD_VARIANTS.COMMON, CARDS_PER_SLIDE.TOUCH, true)}
      </MediaWrapper>
    </div>
  );
};

export default HeadlineNews;
