"use client";

import SportMenu from "rivals/components/shared/Menu/SportMenu";
import { SportMenuOption } from "rivals/components/shared/Menu/SportMenu/types";
import { sportMenuOptionFromString } from "rivals/components/shared/Menu/SportMenu/utils";
import { MenuVariant } from "rivals/components/shared/Menu/types";
import type { Props } from "./types";

export default function DashboardSportMenu({
  sport = SportMenuOption.All,
  sports
}: Props): React.JSX.Element {
  return (
    <SportMenu
      sports={sports}
      value={sportMenuOptionFromString(sport)}
      variant={MenuVariant.Dark}
    />
  );
}
