"use client";

import classNames from "classnames";
import Cookies from "js-cookie";
import { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import Chevron from "rivals/components/shared/Icons/Chevron";
import {
  DASHBOARD_WIDGETS_HIDDEN,
  MAX_NUMBER_OF_WIDGETS,
  ROTATE_90
} from "rivals/shared/constants";
import commonStyles from "rivals/styles/exports.module.scss";
import styles from "./DesktopClientWrapper.module.scss";
import strings from "./strings";
import { Props } from "./types";

/**
 *
 * Client wrapper to pass server component props - HeadlineNews, EventTicker, TeamSelector, and Widgets
 * in order to add user interaction and utilize client side hooks
 * Show widgets (if any) by default, and animate widgets hidden to the right if the cookie is set
 */

const DesktopClientWrapper = ({
  eventTicker,
  headlineNews,
  isNationalUmbrella,
  teamSelector,
  widgets,
  widgetComponents
}: Props): React.JSX.Element => {
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const hasMaxWidgets: boolean =
    (widgetComponents?.length || 0) >= MAX_NUMBER_OF_WIDGETS;
  const hasWidgets: boolean = !!widgetComponents.length;
  const cookieValue: boolean =
    Cookies.get(DASHBOARD_WIDGETS_HIDDEN) !== undefined ? true : false;
  const [hideWidgets, setHideWidgets] = useState<boolean>(false);
  const [forceShowCollapseButton, setForceShowCollapseButton] = useState<
    boolean
  >(false);

  const handleMouseEnter = useCallback(() => {
    setForceShowCollapseButton(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setForceShowCollapseButton(false);
  }, []);

  useEffect(() => {
    setHideWidgets(cookieValue);
  }, [cookieValue]);

  return (
    <div className={styles.container}>
      <div className={styles.overflowContainer}>
        <div
          className={classNames(styles.dashboardRow, {
            [styles.twoColumn]: hasMaxWidgets,
            [styles.fullWidth]:
              !hasWidgets || (hideWidgets && !isNationalUmbrella)
          })}
        >
          {eventTicker}
          {teamSelector}
        </div>
        <div
          className={classNames(styles.dashboardRow, {
            [styles.twoColumn]: hasMaxWidgets,
            [styles.fullWidth]: !hasWidgets || hideWidgets
          })}
        >
          <div
            className={classNames(styles.headlineNews, {
              [styles.twoColumn]: hasMaxWidgets,
              [styles.fullWidth]: !hasWidgets
            })}
          >
            {hasWidgets && !hideWidgets && (
              <div className={styles.linearGradient} />
            )}
            {headlineNews}
          </div>
          {hasWidgets && (
            <CSSTransition
              in={!hideWidgets}
              mountOnEnter
              nodeRef={nodeRef}
              timeout={2000}
              unmountOnExit
            >
              <div
                className={classNames(styles.widgetGrid, {
                  [styles.twoColumn]: hasMaxWidgets,
                  [styles.nationals]: isNationalUmbrella
                })}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={nodeRef}
                role="region"
                tabIndex={0}
              >
                {widgets}
              </div>
            </CSSTransition>
          )}
        </div>
      </div>
      {hasWidgets && (
        <button
          className={classNames(styles.collapseButton, {
            [styles.collapseButtonForTwoColumn]: hasMaxWidgets,
            [styles.collapseButtonExpand]: hideWidgets,
            [styles.nationals]: isNationalUmbrella,
            [styles.forceShowCollapseButton]: forceShowCollapseButton
          })}
          onClick={(): void => {
            if (hideWidgets) {
              Cookies.remove(DASHBOARD_WIDGETS_HIDDEN);
            } else {
              Cookies.set(DASHBOARD_WIDGETS_HIDDEN, "true");
            }
            setHideWidgets(!hideWidgets);
          }}
          type="button"
        >
          <div className={styles.buttonLabel}>
            <span className={styles.buttonText}>
              {hideWidgets ? strings.expand : strings.collapse}
            </span>
            <Chevron
              fill={commonStyles.bone10}
              height={"9"}
              rotate={!hideWidgets ? -ROTATE_90 : ROTATE_90}
              width={"9"}
            />
          </div>
        </button>
      )}
    </div>
  );
};

export default DesktopClientWrapper;
