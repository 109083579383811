import { CareerEventType } from "rivals/shared/interfaces/Prospect";

/**
 * Determines if an EventCard should have a transfer action format or not
 *
 * @param eventType type of PersonEvent belonging to the card
 */
export const isMulticollegeCard = (eventType: CareerEventType): boolean => {
  return [
    CareerEventType.PORTAL_WITHDRAW,
    CareerEventType.PORTAL_ENTRY,
    CareerEventType.PORTAL_INTENT
  ].includes(eventType);
};
