import { useEffect } from "react";
import type { Props } from "./types";

/**
 * Hook to refresh Benji ad slots on soft navigation via
 * Next.js router
 *
 * @param slots - an array of BenjiAdSlots to refresh
 * @param triggerState - a reactive var to trigger the refresh
 */
export default function useBenjiRefresh({ slots, triggerState }: Props): void {
  useEffect(() => {
    if (window.benji && slots.length > 0) {
      window.benji?.refresh && window.benji.refresh(slots);
    }
  }, [slots, triggerState]);
}
